import Auth from "@aws-amplify/auth";
import classNames from "classnames";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { KatSpinner } from "@amzn/katal-react";
import { getLocalStorage, getURL, redirect } from "../../backend/dom";
import { AuthConfig } from "../../lib/AuthConfig";
import styles from "./AuthWrapper.module.css";

const AUTH_STATUS = {
  AUTHENTICATED: "AUTHENTICATED",
  UNAUTHENTICATED: "UNAUTHENTICATED"
};

export function AuthWrapper({ children }: PropsWithChildren<any>): JSX.Element {
  const [authStatus, setAuthStatus] = useState(AUTH_STATUS.UNAUTHENTICATED);
  const [authInit, setAuthInit] = useState(false);
  const [, setTokenSaved] = useState(false);

  useEffect(() => {
    const getCurrentToken = async () => {
      if (!authInit) {
        await AuthConfig.init();
      }
      try {
        if (AuthConfig.validateIdToken()) {
          setAuthStatus(AUTH_STATUS.AUTHENTICATED);
          setAuthInit(true);
        } else {
          await AuthConfig.saveToken();
          setTokenSaved(true);
        }
      } catch (err) {
        console.error(err);
        setAuthStatus(AUTH_STATUS.UNAUTHENTICATED);
        setAuthInit(true);
      }
    };
    getCurrentToken();
  });

  useEffect(() => {
    if (authStatus === AUTH_STATUS.UNAUTHENTICATED && authInit) {
      const redirectURL = getURL();
      getLocalStorage().setItem("redirectURL", redirectURL);
      Auth.federatedSignIn({ customProvider: "AmazonFederate" });
    } else if (authStatus === AUTH_STATUS.AUTHENTICATED && authInit) {
      const redirectURL = getLocalStorage().getItem("redirectURL");
      if (redirectURL) {
        getLocalStorage().removeItem("redirectURL");
        redirect(redirectURL);
      }
    }
  }, [authStatus, authInit]);

  return (
    <>
      {authStatus === AUTH_STATUS.AUTHENTICATED ? (
        children
      ) : (
        <KatSpinner
          id="authSpinner"
          className={classNames(styles.authSpinner)}
          size="large"
          variant="default"
        />
      )}
    </>
  );
}
