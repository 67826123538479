import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";

import en from "../i18n/strings-en.json";

const resources = {
    en: {
        translation: en
    }
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(
        {
            resources: resources,
            debug: false,
            fallbackLng: "en",
            interpolation: {
                escapeValue: false
            }
        },
        (err) => {
            if (err) {
                console.log("Error while initializing i18n", err);
            } else {
                console.log("i18n init complete!");
            }
        }
    );
