import { isAssociationEnabled } from "./ProgramConfig";

export interface ProgramData {
  programId: string;
  marketplaceId: string;
  program: string;
  [key: string]: string;
}

export const emptyProgramData: ProgramData = {
  programId: "",
  marketplaceId: "",
  program: ""
};

class Program {
  readonly name: string;
  readonly isEnabled: (stage: string, marketplaceId: string) => boolean;

  constructor(name: string, isEnabled: (stage: string, marketplaceId: string) => boolean) {
    this.name = name;
    this.isEnabled = isEnabled;
  }
}

const ASSOCIATION = new Program("association", isAssociationEnabled)

const ENABLED_PROGRAMS = [ASSOCIATION];

export const isProgramEnabled = (programName : string, stage: string, marketplaceId: string) : boolean =>
    ENABLED_PROGRAMS.filter(program => program.name === programName).some(program => program.isEnabled(stage, marketplaceId));

export const areEnabledPrograms = (stage: string, marketplaceId: string) : boolean =>
    ENABLED_PROGRAMS.some(program => program.isEnabled(stage, marketplaceId));

export const enabledPrograms = (stage: string, marketplaceId: string) : Program[] =>
    ENABLED_PROGRAMS.filter(program => program.isEnabled(stage, marketplaceId));