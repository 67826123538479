import classNames from "classnames";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { KatAlert, KatBox, KatLink } from "@amzn/katal-react";
import { StageContext } from "../../App";
import { Stage } from "../../common/AccountData";
import { AuthConfig } from "../../lib/AuthConfig";
import styles from "./ProductionAssets.module.css";

const AB_LIONS_TICKET_URL =
  "https://t.corp.amazon.com/create/options?category=B2B&type=ABPrograms&item=ABProgramsService&group=AB-Lions";

export function ProductionAssets(): JSX.Element {
  const { t } = useTranslation();
  const stage = useContext(StageContext);

  const isProd = stage === Stage.prod;
  return (
    <>
      {isProd && (
        <>
          <KatAlert
            persistent
            variant="danger"
            header={`${t("hello-message")} @${AuthConfig.getAlias()}, ${t(
              "prod-alert-header-title"
            )}`}
            description={t("prod-alert-header-description")}
          />
          <KatBox
            className={classNames(styles.footer, "kat-row")}
            variant="ltgrey"
          >
            <p>{t("footer-trouble-partial-message")}</p> &nbsp;
            <KatLink
              href={AB_LIONS_TICKET_URL}
              label={t("ticket")}
              target="_blank"
              rel="noreferrer"
            />
          </KatBox>
        </>
      )}
    </>
  );
}
