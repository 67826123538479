import Auth from "@aws-amplify/auth";
import Amplify from "@aws-amplify/core";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { getLocalStorage, getOrigin } from "../backend/dom";
import { AUTH_CONFIG } from "../common/AccountData";

const SETTINGS = "/settings.json";

interface ParsedToken {
  exp: number;
  nickname: string;
  "custom:POSIX_GROUP": string;
}

class AuthConfigSingleton {
  async init(): Promise<void> {
    const { stage } = await axios
      .get(SETTINGS)
      .then(response => response.data);
    Amplify.configure({
      Auth: {
        mandatorySignIn: true,
        region: "us-west-2",
        userPoolId: AUTH_CONFIG[stage].userPoolId,
        userPoolWebClientId: AUTH_CONFIG[stage].clientId
      },
      oauth: {
        domain: AUTH_CONFIG[stage].domain,
        redirectSignIn: getOrigin(),
        redirectSignOut: getOrigin(),
        scope: ["openid"],
        responseType: "code"
      }
    });
  }

  parseToken(): ParsedToken | Error {
    const idToken = getLocalStorage().getItem("parsedToken");
    if (idToken) {
      return JSON.parse(idToken);
    } else {
      throw new Error("Missing valid Token!");
    }
  }

  validateIdToken(): boolean {
    try {
      const jsonToken = this.parseToken();
      const tokenExpirationDateMilli = (jsonToken as ParsedToken).exp * 1000;
      if(Date.now() < tokenExpirationDateMilli) {
        return true;
      }
    } catch (e) {
      console.error(e);
      return false;
    }
    return false;
  }

  async saveToken(): Promise<void> {
    await Auth.currentAuthenticatedUser();
    const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
    getLocalStorage().setItem("idToken", idToken);
    getLocalStorage().setItem(
      "parsedToken",
      JSON.stringify(jwt_decode(idToken))
    );
  }

  isAdmin(): boolean {
    try {
      const jsonToken = this.parseToken();
      return (jsonToken as ParsedToken)["custom:POSIX_GROUP"] === "ADMIN";
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  getAlias(): string {
    try {
      const jsonToken = this.parseToken();
      return (jsonToken as ParsedToken).nickname;
    } catch (e) {
      console.error(e);
      return "missingAlias";
    }
  }
}

export const AuthConfig = new AuthConfigSingleton();
