import { capitalizeFirstLetter } from "../utils/stringUtils";
import { areEnabledPrograms, enabledPrograms, isProgramEnabled } from "./Program";
import { getCountryCode, getMarketplaceIds } from "./MarketplaceConfig";

class Region {
    readonly name: string;
    readonly flag: string;

    constructor(name: string, flag: string) {
        this.name = name;
        this.flag = flag;
    }
}

const NA = new Region("NA", "flag-us");
const EU = new Region("EU", "flag-eu");
const FE = new Region("FE", "flag-jp");

const AVAILABLE_REGIONS: Region[] = [NA, FE, EU];

export const REGIONS_ON_DISPLAY = AVAILABLE_REGIONS.map(region => ({
  name: region.name,
  value: region.name,
  icon: region.flag
}));

const toFormattedProgram = (name : string) => ({
    name: capitalizeFirstLetter(name),
    value: name
})

export const programsFromMarketplace = (stage: string, marketplaceId: string) =>
        enabledPrograms(stage, marketplaceId).map(item => toFormattedProgram(item.name));

export const programsFromRegion = (stage: string, realm: string) => {
    if (realm) {
        const programList = getMarketplaceIds(stage, realm)!
            .flatMap(marketplaceId => enabledPrograms(stage, marketplaceId))
            .map(program => program.name)

        const uniquePrograms = Array.from(new Set(programList));
        return uniquePrograms.map(toFormattedProgram)
    }
    return [];
}

export const marketplacesFromProgram = (stage: string, program?: string) =>
    AVAILABLE_REGIONS
        .map(region => getMarketplaceIds(stage, region.name)!)
        .flat()
        .filter(marketplaceId => getCountryCode(stage, marketplaceId))
        .map(marketplaceId => ({
            countryCode: getCountryCode(stage, marketplaceId)!,
            marketplaceId: marketplaceId
        }))
        .filter(info => program ?
            isProgramEnabled(program, stage, info.marketplaceId) :
            areEnabledPrograms(stage, info.marketplaceId))
        .map(info => ({
            name: `${info.marketplaceId} - ${info.countryCode}`,
            value: info.marketplaceId,
            icon: `flag-${info.countryCode}`.toLowerCase()
        }));
