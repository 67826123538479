import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { KatButton, KatIcon, KatModal } from "@amzn/katal-react";
import { ProgramData } from "../../common/Program";
import { capitalizeFirstLetter } from "../../utils/stringUtils";
import styles from "./DeleteModal.module.css";

interface DeleteModalProps {
  isModalVisible: boolean;
  programData: ProgramData;
  handleDelete: (programData: ProgramData) => Promise<void>;
  handleCancel: () => void;
}

export function DeleteModal(props: DeleteModalProps): JSX.Element {
  const { t } = useTranslation();

  const [isLoading, onIsLoadingChange] = useState(false);

  const onDeleteButtonClick = () => {
    onIsLoadingChange(true);
    props
      .handleDelete(props.programData)
      .finally(() => onIsLoadingChange(false));
  };

  return (
    <KatModal
      id="deleteModal"
      title={t("delete-modal-title")}
      visible={props.isModalVisible}
      onClose={props.handleCancel}
    >
      <p id="deleteInitialMessage" className={styles.deleteMessage}>
        {t("program-type-dropdown-label")}
        &nbsp;:&nbsp;
        <b>{capitalizeFirstLetter(props.programData.program)}</b>
        <br />
        {t("programid-input-label")}
        &nbsp;:&nbsp;
        <b>{props.programData.programId}</b>
      </p>

      <div slot="footer" className={classNames("kat-group-horizontal")}>
        <KatButton
          id="cancelButton"
          variant="secondary"
          label={t("cancel-button-label")}
          onClick={props.handleCancel}
        >
          <KatIcon name="cancel" size="tiny" slot="icon" />
        </KatButton>
        <KatButton
          id="deleteButton"
          label={t("delete-button-label")}
          className={classNames({ [styles.deleteButton]: isLoading })}
          variant="warning"
          loading={isLoading}
          onClick={onDeleteButtonClick}
        >
          <KatIcon name="delete" size="tiny" slot="icon" />
        </KatButton>
      </div>
    </KatModal>
  );
}
