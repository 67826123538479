import React from "react";
import "./index.css";
import "./config/i18n";
import ReactDOM from "react-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import kpv from "@amzn/katal-platform-versions";
import { App } from "./App";
import { AuthWrapper } from "./component/AuthWrapper/AuthWrapper";
import reportWebVitals from "./utils/reportWebVitals";

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <Helmet>
        <script src={kpv.domains.prod + kpv.themes.flo.stable} />
      </Helmet>
      <AuthWrapper>
        <App />
      </AuthWrapper>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById("root") as HTMLElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
