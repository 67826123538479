import classNames from "classnames";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { KatBox, KatButton, KatDivider, KatIcon } from "@amzn/katal-react";
import { StageContext } from "../../App";
import { createProgram, fetchProgram } from "../../backend/http";
import { emptyProgramData, ProgramData } from "../../common/Program";
import { AuthConfig } from "../../lib/AuthConfig";
import { ProgramDisplay } from "../ProgramDisplay/ProgramDisplay";
import { ProgramModalForm } from "../ProgramModalForm/ProgramModalForm";
import { SearchProgramBar, SearchProgramBarData } from "../SearchProgramBar/SearchProgramBar";
import styles from "./HomePage.module.css";

export function HomePage(): JSX.Element {
  const { t } = useTranslation();
  const stage = useContext(StageContext);
  const [fetchedProgram, setFetchedProgram] = useState<ProgramData | undefined>(
    undefined
  );
  const [isEnableCreate, onChangeEnableCreateModal] = useState(false);
  const [showDeleteSuccessAlert, onShowDeleteSuccessAlertChange] = useState(
    false
  );
  const handleCreateButton = () => onChangeEnableCreateModal(true);

  const handleCreateEvent = (programData: ProgramData) =>
    createProgram({ ...programData }, stage).then(
      response => response.data.entryPoint
    );

  const handleSearchProgram = (data: SearchProgramBarData) => {
    setFetchedProgram(undefined);
    onShowDeleteSuccessAlertChange(false);
    return fetchProgram({ ...data }, stage).then(response => {
      setFetchedProgram(response.data);
    });
  };

  return (
    <>
      {AuthConfig.isAdmin() && (
        <>
          <KatBox
            className={classNames("kat-row", styles.createBox)}
            variant="ltgrey"
          >
            <h2>{t("create-title")}</h2>
            <KatButton
              id="consoleCreateButton"
              label={t("create-button-label")}
              className={classNames(styles.createButton)}
              onClick={handleCreateButton}
            >
              <KatIcon name="plus" size="small" slot="icon" />
            </KatButton>
          </KatBox>
          <ProgramModalForm
            isUpdate={false}
            prefilledProgramData={emptyProgramData}
            isEnableModal={isEnableCreate}
            onChangeEnableModal={onChangeEnableCreateModal}
            principalEventHandler={handleCreateEvent}
          />
          <KatDivider
            className={classNames(styles.katDivider)}
            variant="athens"
          />
        </>
      )}

      <SearchProgramBar
        handleSearchProgram={handleSearchProgram}
        withProgramId
        title={t("search-title")}
      />
      <ProgramDisplay
        showDeleteSuccessAlert={showDeleteSuccessAlert}
        onShowDeleteSuccessAlertChange={onShowDeleteSuccessAlertChange}
        programData={fetchedProgram}
        onProgramDataChange={setFetchedProgram}
      />
    </>
  );
}
