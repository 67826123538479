import { ConfigLoader } from "@amzn/amazon-config-store-type-script-offline-adapter"
import { provideClient } from "./AcsClientHelper"
import { ABProgramsConsoleProgramConfigClientProvider, ABProgramsConsoleProgramConfigContext } from '@amzn/acsabprograms-console-program-config-type-script-client/dist/aBProgramsConsoleProgramConfig'
import { documents as ABProgramsConsoleProgramConfigProdDocuments } from '@amzn/amazon-config-store-type-script-offline-adapter/dist/configuration/acs_ABProgramsConsoleProgramConfig/documents.json'
import { queries as ABProgramsConsoleProgramConfigProdQueries } from '@amzn/amazon-config-store-type-script-offline-adapter/dist/configuration/acs_ABProgramsConsoleProgramConfig/queries.json'
import { queries as ABProgramsConsoleProgramConfigProdHfQueries } from '@amzn/amazon-config-store-type-script-offline-adapter/dist/configuration/acs_ABProgramsConsoleProgramConfig/hierarchical_queries.json'
import { documents as ABProgramsConsoleProgramConfigBetaDocuments } from '@amzn/amazon-config-store-type-script-offline-adapter/dist/configuration/devo/acs_ABProgramsConsoleProgramConfig/documents.json'
import { queries as ABProgramsConsoleProgramConfigBetaQueries } from '@amzn/amazon-config-store-type-script-offline-adapter/dist/configuration/devo/acs_ABProgramsConsoleProgramConfig/queries.json'
import { queries as ABProgramsConsoleProgramConfigBetaHfQueries } from '@amzn/amazon-config-store-type-script-offline-adapter/dist/configuration/devo/acs_ABProgramsConsoleProgramConfig/hierarchical_queries.json'

const abProgramsConsoleProgramConfigProd: ConfigLoader = { load: () => ({
        documents: ABProgramsConsoleProgramConfigProdDocuments,
        exactQueries: ABProgramsConsoleProgramConfigProdQueries,
        hierarchicalFallbackQueries: ABProgramsConsoleProgramConfigProdHfQueries
    })}

const abProgramsConsoleProgramConfigBeta: ConfigLoader = { load: () => ({
        documents: ABProgramsConsoleProgramConfigBetaDocuments,
        exactQueries: ABProgramsConsoleProgramConfigBetaQueries,
        hierarchicalFallbackQueries: ABProgramsConsoleProgramConfigBetaHfQueries
    })}

const abProgramsConsoleConfigClient = (stage : string) =>
    provideClient(new ABProgramsConsoleProgramConfigClientProvider(), stage, abProgramsConsoleProgramConfigProd, abProgramsConsoleProgramConfigBeta);


export const isAssociationEnabled = (stage: string, marketplaceId : string) : boolean => {
    const association = abProgramsConsoleConfigClient(stage).getAssociation(new ABProgramsConsoleProgramConfigContext(marketplaceId));
    return Boolean(association?.isEnabled);
}

export const getPartnerId = (stage: string, marketplaceId : string) : string => {
    const partnerId = abProgramsConsoleConfigClient(stage).getAssociation(new ABProgramsConsoleProgramConfigContext(marketplaceId))?.partnerId;
    return partnerId ? partnerId : "";
}
