import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

interface CopyLinkProps {
  className: string;
  link: string;
}

export function CopyLink(props: CopyLinkProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <p id="entryPointMessage" className={classNames(props.className)}>
      {t("url-partial-message") as string}
      &nbsp;:&nbsp;
      <a href={props.link} target="_blank" rel="noreferrer">
        {props.link}
      </a>
    </p>
  );
}
