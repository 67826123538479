import classNames from "classnames";
import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { KatAlert, KatButton, KatDataTable, KatIcon } from "@amzn/katal-react";
import { StageContext } from "../../App";
import { deleteProgram, updateProgram } from "../../backend/http";
import { ErrorProps } from "../../common/Error";
import { ProgramData } from "../../common/Program";
import { AuthConfig } from "../../lib/AuthConfig";
import { capitalizeFirstLetter } from "../../utils/stringUtils";
import { CopyLink } from "../CopyLink/CopyLink";
import { CopyTooltip } from "../CopyTooltip/CopyTooltip";
import { DeleteModal } from "../DeleteModal/DeleteModal";
import { ProgramModalForm } from "../ProgramModalForm/ProgramModalForm";
import styles from "./ProgramDisplay.module.css";

const BUTTON_INPUT_STYLE = "kat-col-xs-5";

export interface ProgramDisplayProps {
  programData?: ProgramData;
  onProgramDataChange: (value: ProgramData | undefined) => void;
  onShowDeleteSuccessAlertChange: (value: boolean) => void;
  showDeleteSuccessAlert: boolean;
}

export function ProgramDisplay(props: ProgramDisplayProps): JSX.Element {
  const { t } = useTranslation();
  const stage = useContext(StageContext);
  const [isEnableUpdate, onIsEnableUpdateChange] = useState(false);
  const [isEnableDelete, onIsEnableDeleteChange] = useState(false);
  const [deleteError, onDeleteErrorChange] = useState<ErrorProps | undefined>(
    undefined
  );

  const { entryPoint, ...displayProgram } = props.programData
    ? props.programData
    : { entryPoint: undefined };

  const calculatedColumns = useMemo(
    () =>
      Object.keys(displayProgram).map(key => ({
        label: capitalizeFirstLetter(key),
        property: key
      })),
    [displayProgram]
  );

  const handleDeleteModalClose = () => onIsEnableDeleteChange(false);

  const handleDeleteProgram = (programData: ProgramData) => {
    return deleteProgram(programData, stage)
      .then(() => {
        props.onProgramDataChange(undefined);
        props.onShowDeleteSuccessAlertChange(true);
      })
      .catch(error =>
        onDeleteErrorChange({
          statusCode: error.response.status,
          message: error.response.data.error
        })
      )
      .finally(handleDeleteModalClose);
  };

  const handleUpdateEvent = programData =>
    updateProgram({ ...programData }, stage).then(response => {
      props.onProgramDataChange({
        ...programData,
        entryPoint: response.data.entryPoint
      });
      return response.data.entryPoint;
    });

  const handleButton = (enabler: (value: boolean) => void) => {
    enabler(true);
    onDeleteErrorChange(undefined);
  };

  return (
    <>
      {props.showDeleteSuccessAlert && (
        <div className={classNames(styles.errorAlertContainer)}>
          <KatAlert
            id="deleteSuccessAlert"
            className={classNames(styles.errorAlert)}
            header={t("delete-success-message")}
            variant="success"
          ></KatAlert>
        </div>
      )}
      {props.programData && (
        <>
          {deleteError && (
            <div className={classNames(styles.errorAlertContainer)}>
              <KatAlert
                id="deleteErrorAlert"
                className={classNames(styles.errorAlert)}
                description={`${t("status-code")}: ${deleteError.statusCode}`}
                header={deleteError.message}
                variant="danger"
              ></KatAlert>
            </div>
          )}
          <div className={styles.displayFlexContainer}>
            <div className={classNames(styles.displayDataTable)}>
              <KatDataTable
                id="programDataTable"
                titleLabel={`${capitalizeFirstLetter(
                  props.programData.program
                )} ${t("details-label")}`}
                columns={calculatedColumns}
                rowData={[displayProgram]}
              />
            </div>
            {AuthConfig.isAdmin() && (
              <div className={styles.buttonContainer}>
                <KatButton
                  id="consoleUpdateButton"
                  className={classNames(
                    styles.updateButton,
                    BUTTON_INPUT_STYLE
                  )}
                  label={t("update-button-label")}
                  onClick={() => handleButton(onIsEnableUpdateChange)}
                  variant="secondary"
                >
                  <KatIcon name="edit" size="small" slot="icon" />
                </KatButton>

                <ProgramModalForm
                  isUpdate
                  isEnableModal={isEnableUpdate}
                  onChangeEnableModal={onIsEnableUpdateChange}
                  prefilledProgramData={props.programData}
                  principalEventHandler={handleUpdateEvent}
                />
                <KatButton
                  id="consoleDeleteButton"
                  className={classNames(
                    styles.deleteButton,
                    BUTTON_INPUT_STYLE
                  )}
                  label={t("delete-button-label")}
                  variant="warning"
                  onClick={() => handleButton(onIsEnableDeleteChange)}
                >
                  <KatIcon name="delete" size="small" slot="icon" />
                </KatButton>
                <DeleteModal
                  isModalVisible={isEnableDelete}
                  handleCancel={handleDeleteModalClose}
                  programData={props.programData}
                  handleDelete={handleDeleteProgram}
                />
              </div>
            )}
          </div>
          <div
            className={classNames(styles.entryPointMessageBox, "kat-col-xs-12")}
          >
            <CopyLink
              className={styles.entryPointMessage}
              link={props.programData.entryPoint}
            />
            <CopyTooltip
              className={classNames(styles.copyDiv)}
              copyText={props.programData.entryPoint}
              position="right"
            />
          </div>
        </>
      )}
    </>
  );
}
