import React, { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { KatBox, KatTab, KatTabs } from "@amzn/katal-react";
import { fetchStage } from "./backend/http";
import { HomePage } from "./component/HomePage/HomePage";
import { ListPrograms } from "./component/ListPrograms/ListPrograms";
import { ProductionAssets } from "./component/ProductionAssets/ProductionAssets";

export const StageContext = createContext("");

export function App(): JSX.Element {
  const { t } = useTranslation();
  const [stage, setStage] = useState("");

  useEffect(() => {
    fetchStage().then(res => {
      setStage(res.data.stage);
    });
  }, []);

  return (
    <>
      {stage && (
        <StageContext.Provider value={stage}>
          <KatBox variant="nordic">
            <h1>{t("console-title")} </h1>
          </KatBox>

          <ProductionAssets />

          <KatTabs selected="1">
            <KatTab tabId="1" label={t("home-label")}>
              <HomePage />
            </KatTab>
            <KatTab tabId="2" label={t("list-title-label")}>
              <ListPrograms />
            </KatTab>
          </KatTabs>
        </StageContext.Provider>
      )}
    </>
  );
}
