import classNames from "classnames";
import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  KatAlert,
  KatBox,
  KatButton,
  KatDivider,
  KatDropdown,
  KatIcon,
  KatInput
} from "@amzn/katal-react";
import { ErrorProps } from "../../common/Error";
import { programsFromRegion, REGIONS_ON_DISPLAY } from "../../common/Marketplace";
import styles from "./SearchProgramBar.module.css";
import { StageContext } from "../../App";

export interface SearchProgramBarData {
  programId: string;
  program: string;
  region: string;
}

export const EMPTY_SEARCH_PROGRAM_BAR_DATA: SearchProgramBarData = {
  programId: "",
  program: "",
  region: ""
};

interface SearchProgramBarProps {
  handleSearchProgram: (value: SearchProgramBarData) => Promise<void>;
  withProgramId: boolean;
  title: string;
}

export function SearchProgramBar(props: SearchProgramBarProps): JSX.Element {
  const { t } = useTranslation();
  const stage = useContext(StageContext);
  const [error, setError] = useState<ErrorProps | undefined>(undefined);
  const [isSearchLoading, setSearchLoading] = useState(false);
  const [searchProgramBarData, setSearchProgramBarData] = useState<SearchProgramBarData>(
      EMPTY_SEARCH_PROGRAM_BAR_DATA
  );

  const programsForRegion = useMemo(
    () => programsFromRegion(stage, searchProgramBarData.region),
    [searchProgramBarData.region, stage]
  );
  const isProgramDropdownDisabled = programsForRegion.length === 0;
  const isSearchButtonDisabled = props.withProgramId
    ? !searchProgramBarData.programId
    : !searchProgramBarData.program;
  const programIdInputDisabled = !(
    searchProgramBarData.program && searchProgramBarData.region
  );
  const programPlaceholder = isProgramDropdownDisabled
    ? t("no-existing-program-dropdown-placeholder")
    : "";

  const handleOnSearchButtonClick = () => {
    setError(undefined);
    setSearchLoading(true);
    props
      .handleSearchProgram(searchProgramBarData)
      .catch(error => {
        setError({
          statusCode: error.response.status,
          message: error.response.data.error
        });
      })
      .finally(() => {
        setSearchLoading(false);
      });
  };

  const handleRegionDropdown = event =>
    setSearchProgramBarData({
      ...EMPTY_SEARCH_PROGRAM_BAR_DATA,
      region: event.detail.value
    });

  const handleProgramTypeDropdown = event =>
    setSearchProgramBarData({
      ...EMPTY_SEARCH_PROGRAM_BAR_DATA,
      region: searchProgramBarData.region,
      program: event.detail.value
    });

  const handleProgramIdInput = event =>
    setSearchProgramBarData({
      ...searchProgramBarData,
      programId: event.target.value
    });

  return (
    <>
      <KatBox>
        <p className={classNames(styles.searchBarTitle)}>{props.title}</p>
        <div className={classNames(styles.flexContainer)}>
          <KatDropdown
            id="consoleRegionDropdown"
            className={classNames(styles.flexItem)}
            label={t("region-dropdown-label")}
            options={REGIONS_ON_DISPLAY}
            value={searchProgramBarData.region}
            placeholder={t("region-dropdown-placeholder")}
            onChange={handleRegionDropdown}
          />
          <KatDropdown
            id="consoleProgramsDropdown"
            className={classNames(styles.flexItem)}
            label={t("program-type-dropdown-label")}
            options={programsForRegion}
            disabled={isProgramDropdownDisabled}
            placeholder={programPlaceholder}
            value={searchProgramBarData.program}
            onChange={handleProgramTypeDropdown}
          />
          {props.withProgramId && (
            <KatInput
              id="consoleProgramIdInput"
              className={classNames(styles.flexItem)}
              label={t("programid-input-label")}
              type="search"
              disabled={programIdInputDisabled}
              value={searchProgramBarData.programId}
              onInput={handleProgramIdInput}
            />
          )}
          <KatButton
            id="consoleSearchButton"
            className={classNames(styles.searchButton, styles.flexItem, {
              [styles.searchButtonDisabled]:
                isSearchButtonDisabled || isSearchLoading
            })}
            label={t("search-button-label")}
            loading={isSearchLoading}
            onClick={handleOnSearchButtonClick}
            disabled={isSearchButtonDisabled}
          >
            <KatIcon name="search" size="small" slot="icon" />
          </KatButton>
        </div>
      </KatBox>
      <KatDivider variant="athens" />
      {error && (
        <div className={classNames(styles.errorAlertContainer)}>
          <KatAlert
            id="fetchErrorAlert"
            className={classNames(styles.errorAlert)}
            description={`${t("status-code")}: ${error.statusCode}`}
            header={error.message}
            variant="warning"
          ></KatAlert>
        </div>
      )}
    </>
  );
}
