interface AuthPoolConfig {
  userPoolId: string;
  clientId: string;
  domain: string;
}

export enum Stage {
  beta = "beta",
  prod = "prod"
}

export const AUTH_CONFIG: Record<Stage, AuthPoolConfig> = {
  beta: {
    userPoolId: "us-west-2_X2FXPBifd",
    clientId: "6ldmmju4q9ffqkpddgiq65cjt0",
    domain: "abprograms-console-beta.auth.us-west-2.amazoncognito.com"
  },
  prod: {
    userPoolId: "us-west-2_6Kg9vU4Gq",
    clientId: "4npj6iehf9avcgn1k06sp72suk",
    domain: "abprograms-console-prod.auth.us-west-2.amazoncognito.com"
  }
};

interface RegionCodes {
  NA: string;
  EU: string;
  FE: string;
}

export const API_REGION_FOR_STAGE: Record<Stage, RegionCodes> = {
  beta: {
    NA: "us-west-2",
    EU: "us-west-2",
    FE: "us-west-2"
  },
  prod: {
    NA: "us-east-1",
    EU: "eu-west-1",
    FE: "us-west-2"
  }
};

export const INVOKE_API_ID: Record<Stage, RegionCodes> = {
  beta: {
    NA: "afl1ktdiib",
    EU: "afl1ktdiib",
    FE: "afl1ktdiib"
  },
  prod: {
    NA: "wx0jjlliga",
    EU: "6nmpp35ul9",
    FE: "d7t9gbt9h5"
  }
};
