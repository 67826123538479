import axios from "axios";
import { API_REGION_FOR_STAGE, INVOKE_API_ID } from "../common/AccountData";
import { ProgramData } from "../common/Program";
import { AuthConfig } from "../lib/AuthConfig";
import { getLocalStorage } from "./dom";
import { getRegion } from "../common/MarketplaceConfig";

export interface FetchProgramProps {
  program: string;
  programId: string;
  region: string;
}

export interface FetchAllProgramsProps {
  program: string;
  region: string;
}

export async function fetchStage() {
  return await axios.get("/settings.json");
}

const authAxios = () =>
  axios.create({
    headers: {
      authorization: getLocalStorage().getItem("idToken")
    }
  });

function buildBaseUrl(region: string, stage: string): string {
  const awsRegion = API_REGION_FOR_STAGE[stage][region];
  const apiId = INVOKE_API_ID[stage][region];
  return `https://${apiId}.execute-api.${awsRegion}.amazonaws.com/prod`;
}

export async function fetchProgram(props: FetchProgramProps, stage: string) {
  const apiURL = buildBaseUrl(props.region, stage);
  return await authAxios().get(`${apiURL}/${props.program}/${props.programId}`);
}

export async function updateProgram(data: ProgramData, stage: string) {
  const region = getRegion(stage, data.marketplaceId)!;
  data.lastActor = AuthConfig.getAlias();
  const apiURL = buildBaseUrl(region, stage)
    .concat(`/${data.program}`)
    .concat(`/${data.programId}`);
  return await authAxios().post(apiURL, data);
}

export async function createProgram(data: ProgramData, stage: string) {
  const region = getRegion(stage, data.marketplaceId)!;
  data.lastActor = AuthConfig.getAlias();
  const apiURL = buildBaseUrl(region, stage)
      .concat(`/${data.program}`);
  return await authAxios().post(apiURL, data);
}

export async function deleteProgram(programData: ProgramData, stage: string) {
  const data = { lastActor: AuthConfig.getAlias() };
  const region = getRegion(stage, programData.marketplaceId)!;
  const apiURL = buildBaseUrl(region, stage);
  return await authAxios().put(
    `${apiURL}/${programData.program}/${programData.programId}`,
    data
  );
}
export async function fetchAllPrograms(props: FetchAllProgramsProps, stage: string) {
    const apiURL = buildBaseUrl(props.region, stage);
    return await authAxios().get(`${apiURL}/${props.program}`);
}
