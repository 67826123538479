import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { KatLink, KatTooltip } from "@amzn/katal-react";
import styles from "./CopyTooltip.module.css";

interface CopyTooltipProps {
  className: string;
  copyText: string;
  position: KatTooltip.Position;
}

export function CopyTooltip(props: CopyTooltipProps): JSX.Element {
  const { t } = useTranslation();
  const [copyMessage, onCopyMessageChange] = useState("initial-copy-message");

  const handleClick = () => {
    navigator.clipboard.writeText(props.copyText);
    onCopyMessageChange("success-copy-message");
    (document.activeElement as HTMLElement).blur();
  };

  const handleMouseLeave = () => onCopyMessageChange("initial-copy-message");

  return (
    <div
      id="copyDiv"
      className={classNames(props.className, styles.copyTooltip)}
      onClick={handleClick}
      onMouseLeave={handleMouseLeave}
    >
      <KatTooltip
        label={t(copyMessage)}
        position={props.position}
        triggerIcon="content_copy"
        size="small"
      >
        <KatLink />
      </KatTooltip>
    </div>
  );
}
